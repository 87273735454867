<template>
  <v-layout row wrap>
    <v-flex xs12 mb-5>
      <label class="right keyboard-input-toggler">
        <input type="checkbox" v-model="keyboard" class="d-none hidden" />
        <span class="ml-1 display-1">
          <font-awesome-icon icon="keyboard" :color="keyboard ? '#82b1ff' : '#c0c0c0'" />
        </span>
      </label>
    </v-flex>
    <v-flex xs12 mb-5 text-xs-center>
      <v-layout row wrap>
        <v-flex xs12 mb-4 class="display-2 title-iconed ticket">
          <span :class="error ? 'red--text text--darken-2' : ''">
            {{
            $tc("ticket", 1)
            }}
          </span>
        </v-flex>
        <v-flex
          xs12
          v-if="!model.id"
          :class="
            error
              ? 'display-1 red--text text--darken-2'
              : 'display-1 blue--text text--lighten-1'
          "
        >{{ $t("awaiting-scan") }}</v-flex>
        <v-flex xs12 v-if="model.id" green--text text--darken-2 class="display-1">
          <v-layout row wrap>
            <v-flex xs12 mb-4>
              <span v-if="model.user">{{ model.user.first_name }} {{ model.user.last_name }}</span>
            </v-flex>
            <v-flex xs12>
              <font-awesome-icon icon="check-circle" color="#5aab2e" class="display-3" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap>
        <v-spacer></v-spacer>
        <v-flex xs6 v-if="keyboard" blue--text text--lighten-1 class="display-1">
          <v-text-field v-model="keyboardInput" autofocus box clearable>
            <template slot="label">
              <div>
                {{ $t("type-in-or-scan") }}
                <i
                  class="blue--text text--lighten-1"
                >{{ $tc("ticket", 2) }}</i>
              </div>
            </template>
          </v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-flex>
    <v-flex xs12 v-if="success" text-xs-center>
      <v-layout row wrap green--text text--darken-2>
        <v-flex xs12 mb-4 class="display-1">
          {{ $t("welcome") }} {{ model.user.first_name }}
          {{ model.user.last_name }}!
        </v-flex>
        <v-flex xs12 class="headline" v-if="success">{{ success }}</v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-if="error" xs12 red--text text--darken-2 class="display-1" text-xs-center>{{ error }}</v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    groupPluginId: {
      type: Number,
      required: true,
    },
    ticketTemplateId: {
      type: Number,
      required: true,
    },
    ticketMask: {
      type: String,
    },
  },
  data: () => ({
    scannerInput: [],
    keyboard: false,
    keyboardInput: null,
    model: {},
    error: "",
    success: "",
    timeout: null,
  }),
  computed: {
    inputValue() {
      return this.scannerInput.join("");
    },
  },
  watch: {
    keyboard() {
      this.keyboardInput = null;
    },
    keyboardInput(val) {
      if (val) {
        val = val.replace(/\s/g, "");
        this.validateInput(val);
      }
    },
    inputValue(val) {
      if (val) {
        this.validateInput(val);
      }
    },
  },
  mounted() {
    this.playSuccessSound();
    window.addEventListener("keydown", (event) => {
      const charList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ";
      const key = event.key.toUpperCase();
      if (charList.indexOf(key) === -1) return;
      this.scannerInput.push(key);
    });
  },
  methods: {
    playErrorSound() {
      if (window.sounds) {
        window.sounds["error.mp3"].play();
      }
    },
    playSuccessSound() {
      if (window.sounds) {
        window.sounds["success.mp3"].play();
      }
    },
    validateInput(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.scannerInput = [];
      }, 500);

      let mask = this.ticketMask ? this.ticketMask : "[A-Za-z0-9]{5}";
      const ticketRegExp = new RegExp(`^T${this.ticketTemplateId}(${mask})$`);

      if (ticketRegExp.test(val)) {
        this.scannerInput = [];
        this.model = {};
        this.error = "";
        this.success = "";
        this.checkInTicket(val);
      }
    },
    checkInTicket(code) {
      if (!code) return;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          code: code,
        },
      ];

      this.$api.groupTickets
        .checkin(...params)
        .then((response) => {
          if (response && response.data.status == "ok") {
            if (response.data.data.valid) {
              this.keyboardInput = null;
              this.model = response.data.data;

              this.playSuccessSound();

              this.success = this.$t("you-are-checked-in");

              setTimeout(() => {
                this.success = "";
                this.model = {};
              }, 5000);
            } else {
              this.playErrorSound();

              this.error = this.$t("ticket-already-used");

              setTimeout(() => {
                this.error = "";
              }, 30000);
            }
          } else {
            this.playErrorSound();

            this.error = response.data.error.message;

            setTimeout(() => {
              this.error = "";
            }, 30000);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.keyboard-input-toggler {
  cursor: pointer;
}
.hidden {
  opacity: 0;
}
.title-iconed span {
  padding-right: 80px;
  position: relative;
}
.title-iconed span:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.title-iconed.ticket span:after {
  background-image: url("~@/assets/images/icon-ticket.png");
}
</style>
