<template>
  <v-layout row wrap>
    <v-flex xs12 mb-5>
      <label class="right keyboard-input-toggler">
        <input type="checkbox" v-model="keyboard" class="d-none hidden" />
        <span class="ml-1 display-1">
          <font-awesome-icon icon="keyboard" :color="keyboard ? '#82b1ff' : '#c0c0c0'" />
        </span>
      </label>
    </v-flex>
    <v-flex xs12 mb-5>
      <v-layout row wrap>
        <v-flex xs6 text-xs-center>
          <v-layout row wrap>
            <v-flex xs12 mb-4 class="display-2 title-iconed ticket">
              <span
                :class="
                  error.type == 'ticket' ? 'red--text text--darken-2' : ''
                "
              >{{ $tc("ticket", 1) }}</span>
            </v-flex>
            <v-flex
              xs12
              v-if="!model.id"
              :class="
                error.type == 'ticket'
                  ? 'display-1 red--text text--darken-2'
                  : 'display-1 blue--text text--lighten-1'
              "
            >{{ $t("awaiting-scan") }}</v-flex>
            <v-flex xs12 v-if="model.id" green--text text--darken-2 class="display-1">
              <v-layout row wrap>
                <v-flex xs12 mb-4>
                  <span v-if="model.user">
                    {{ model.user.first_name }}
                    {{ model.user.last_name }}
                  </span>
                </v-flex>
                <v-flex xs12>
                  <font-awesome-icon icon="check-circle" color="#5aab2e" class="display-3" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 text-xs-center>
          <v-layout row wrap>
            <v-flex xs12 mb-4 class="display-2 title-iconed chip">
              <span
                :class="error.type == 'token' ? 'red--text text--darken-2' : ''"
              >{{ $tc("chip", 1) }}</span>
            </v-flex>
            <v-flex xs12>
              <v-layout row wrap>
                <v-flex xs2 v-if="token"></v-flex>
                <v-flex :class="token ? 'xs8' : 'xs12'">
                  <v-layout row wrap>
                    <v-flex
                      xs12
                      v-if="!token"
                      :class="
                        error.type == 'token'
                          ? 'display-1 red--text text--darken-2'
                          : 'display-1 blue--text text--lighten-1'
                      "
                    >{{ $t("awaiting-scan") }}</v-flex>
                    <v-flex xs12 v-if="token" green--text text--darken-2 class="display-1">
                      <v-layout row wrap>
                        <v-flex xs12 mb-4>{{ $t("paired") }}</v-flex>
                        <v-flex xs12>
                          <font-awesome-icon icon="check-circle" color="#5aab2e" class="display-3" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex v-if="token" xs2 style="align-self: center;">
                  <v-btn flat icon color="grey" @click="clearToken">
                    <v-icon>cached</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap>
        <v-spacer></v-spacer>
        <v-flex xs6 v-if="keyboard" blue--text text--lighten-1 class="display-1">
          <v-text-field v-model="keyboardInput" autofocus box clearable>
            <template slot="label">
              <div>
                {{ $t("type-in-or-scan") }}
                <i
                  class="blue--text text--lighten-1"
                >{{ $tc("ticket", 2) }}</i>
                <span class="px-2 text-lowercase">{{ $t("or") }}</span>
                <i class="blue--text text--lighten-1">{{ $tc("chip", 2) }}</i>
              </div>
            </template>
          </v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-flex>
    <v-flex xs12 v-if="success" text-xs-center pt-5>
      <v-layout row wrap green--text text--darken-2>
        <v-flex xs12 mb-4 class="display-1">
          Welcome {{ model.user.first_name }}
          {{ model.user.last_name }}!
        </v-flex>
        <v-flex xs12 class="headline" v-if="success">{{ success }}</v-flex>
      </v-layout>
    </v-flex>
    <v-flex
      v-if="error.type"
      xs12
      pt-5
      red--text
      text--darken-2
      class="display-1"
      text-xs-center
    >{{ error.message }}</v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    groupPluginId: {
      type: Number,
      required: true,
    },
    ticketTemplateId: {
      type: Number,
      required: true,
    },
    ticketMask: {
      type: String,
    },
  },
  data: () => ({
    scannerInput: [],
    keyboard: false,
    keyboardInput: null,
    model: {},
    ticket: null,
    token: null,
    error: {},
    success: "",
    timeout: null,
    processing: false,
    reset: false,
  }),
  computed: {
    inputValue() {
      return this.scannerInput.join("").replace(/\s/g, "");
    },
  },
  watch: {
    keyboard() {
      this.keyboardInput = null;
    },
    keyboardInput(val) {
      if (val) {
        val = val.replace(/\s/g, "");
        this.beforeValidate(val);
      }
    },
    inputValue(val) {
      if (val) {
        this.beforeValidate(val);
      }
    },
    ticket(val) {
      if (val) {
        this.createPhysicalToken();
      }
    },
    token(val) {
      if (val) {
        this.createPhysicalToken();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.playSuccessSound();
      window.addEventListener("keydown", (event) => {
        const charList = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ";
        const key = event.key.toUpperCase();
        if (charList.indexOf(key) === -1) return;
        this.scannerInput.push(key);
      });
    });
  },
  methods: {
    clearErrors() {
      setTimeout(() => {
        this.error = {};
      }, 30000);
    },
    playErrorSound() {
      if (window.sounds) {
        window.sounds["error.mp3"].play();
      }
    },
    playSuccessSound() {
      if (window.sounds) {
        window.sounds["success.mp3"].play();
      }
    },
    clearToken() {
      this.token = "";
    },
    beforeValidate(val) {
      if (this.processing) return;
      if (this.reset) {
        this.reset = false;
        this.model = "";
        this.ticket = "";
        this.token = "";
        this.success = "";
      }
      this.validateInput(val);
    },
    validateInput(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.scannerInput = [];
      }, 500);

      let mask = this.ticketMask ? this.ticketMask : "[A-Za-z0-9]{5}";
      const ticketRegExp = new RegExp(`^T${this.ticketTemplateId}(${mask})$`);
      const tokenRegExp = new RegExp("^([A-F0-9]{2}){7}$");

      if (ticketRegExp.test(val)) {
        this.checkTicket(val).then((response) => {
          if (response && response.data.status == "ok") {
            if (response.data.data.valid) {
              this.error = {};
              this.model = response.data.data;
              this.ticket = val;
              this.scannerInput = [];
              this.keyboardInput = null;
            } else {
              this.success = "";
              this.playErrorSound();
              this.error = {
                type: "ticket",
                message: this.$t("ticket-already-used"),
              };
              this.clearErrors();
              this.scannerInput = [];
              this.keyboardInput = null;
              this.model = {};
              this.ticket = null;
            }
          } else {
            this.success = "";
            this.playErrorSound();
            this.error = {
              type: "ticket",
              message: response.data.error.message,
            };
            this.clearErrors();
            this.scannerInput = [];
            this.keyboardInput = null;
            this.model = {};
            this.ticket = null;
          }
        });
      }
      if (tokenRegExp.test(val)) {
        this.token = val;
        this.scannerInput = [];
        this.keyboardInput = null;
      }
    },
    checkTicket(code) {
      if (!code) return;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          code: code,
        },
      ];

      return this.$api.groupTickets.check(...params);
    },
    checkInTicket() {
      if (!this.ticket) {
        this.processing = false;
        return;
      }

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          code: this.ticket,
        },
      ];

      this.$api.groupTickets
        .checkin(...params)
        .then((response) => {
          if (response && response.data.status == "ok") {
            if (response.data.data.valid) {
              this.processing = false;
              this.reset = true;

              this.playSuccessSound();

              this.success = this.$t("checked-in-and-chip-assigned");

              setTimeout(() => {
                if (this.reset) {
                  this.success = "";
                  this.model = {};
                  this.ticket = null;
                  this.token = null;
                }
              }, 5000);
            } else {
              this.processing = false;

              this.playErrorSound();

              this.error = {
                type: "ticket",
                message: this.$t("ticket-already-used"),
              };

              this.ticket = null;

              this.clearErrors();
            }
          } else {
            this.processing = false;

            this.playErrorSound();

            this.error = {
              type: "ticket",
              message: response.data.error.message,
            };

            this.ticket = null;

            this.clearErrors();
          }
        })
        .catch(() => {});
    },
    createPhysicalToken() {
      if (this.token && this.model.user && this.model.user.id) {
        this.processing = true;

        const specs = [
          this.groupId,
          this.model.user.id,
          "rfid",
          {
            user_id: this.model.user.id,
            token: this.token,
            type: "rfid",
          },
        ];

        this.$api.groupUserPhysicalTokens
          .create(...specs)
          .then((response) => {
            if (response && response.data.status == "ok") {
              this.error = {};

              this.checkInTicket();
            } else {
              this.processing = false;
              this.success = "";

              this.playErrorSound();

              this.error = {
                type: "token",
                message: this.$t("chip-already-used"),
              };

              this.token = null;

              this.clearErrors();
            }
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
.keyboard-input-toggler {
  cursor: pointer;
}
.hidden {
  opacity: 0;
}
.title-iconed span {
  padding-right: 80px;
  position: relative;
}
.title-iconed span:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.title-iconed.ticket span:after {
  background-image: url("~@/assets/images/icon-ticket.png");
}
.title-iconed.chip span:after {
  background-image: url("~@/assets/images/icon-chip.png");
}
</style>
