<template>
  <v-container class="general">
    <v-layout v-if="!loading" row wrap>
      <v-flex xs12 v-if="!ready" text-xs-center>
        <v-btn large class="blue-button superbig" @click="initPlay">{{
          $t("lets-start")
        }}</v-btn>
      </v-flex>
      <v-flex xs12 v-if="!usePhysicalToken && ready">
        <Ticket
          :groupId="groupId"
          :groupPluginId="groupPluginId"
          :ticketTemplateId="ticketTemplateId"
        />
      </v-flex>
      <v-flex xs12 v-if="usePhysicalToken && ready">
        <TicketWithToken
          :groupId="groupId"
          :groupPluginId="groupPluginId"
          :ticketTemplateId="ticketTemplateId"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Ticket from "@/components/Checkin/Ticket.vue";
import TicketWithToken from "@/components/Checkin/TicketWithToken.vue";

export default {
  components: {
    Ticket,
    TicketWithToken,
  },
  data: () => ({
    loading: false,
    ready: false,
    plugin: {},
    // ticketMask: null,
  }),
  computed: {
    ...mapGetters(["getLeftMenu"]),
    groupId() {
      return Number(this.$route.params.group_id);
    },
    groupPluginId() {
      return Number(this.$route.params.group_plugin_id);
    },
    ticketTemplateId() {
      return Number(this.$route.params.ticket_template_id);
    },
    usePhysicalToken() {
      return this.plugin.attributes &&
        this.plugin.attributes.ticketwizard_wardrobe_enabled
        ? true
        : false;
    },
  },
  mounted() {
    this.getGroupPlugin();

    this.$nextTick(() => {
      window.addEventListener("blur", (event) => {
        this.ready = false;
      });
    });
  },
  methods: {
    initPlay() {
      window.sounds = new Object();

      let error = new Audio(require("@/assets/sounds/error.mp3"));
      let success = new Audio(require("@/assets/sounds/success.mp3"));

      error.load();
      success.load();

      window.sounds["error.mp3"] = error;
      window.sounds["success.mp3"] = success;

      this.ready = true;
    },
    getGroupPlugin() {
      this.loading = true;

      const params = [this.groupId, this.groupPluginId, { with_attributes: 1 }];

      this.$api.groupPlugins.get(...params).then((res) => {
        this.loading = false;

        if (!res || !res.data.data) return;

        this.plugin = res.data.data;
      });
    },
  },
};
</script>

<style scooped>
.superbig {
  font-size: 28px;
  line-height: 32px;
  padding: 50px 80px;
  height: auto !important;
  margin-top: calc(((100vh - 180px) / 2) - 70px);
}
</style>
